/**
 * PHP 함수 number_format 같이 천자리마다 ,를 자동으로 찍어줌
 * @param num number|string : 숫자
 * @param decimals int default 0 : 보여질 소숫점 자리숫
 * @param dec_point char default . : 소수점을 대체 표시할 문자
 * @param thousands_sep char default , : 천자리 ,를 대체 표시할 문자
 * @returns {string}
 */

export default (num, decimals, dec_point, thousands_sep) => {
  num = parseFloat(num);
  if(isNaN(num)) return '0';

  if(typeof(decimals) == 'undefined') decimals = 0;
  if(typeof(dec_point) == 'undefined') dec_point = '.';
  if(typeof(thousands_sep) == 'undefined') thousands_sep = ',';
  decimals = Math.pow(10, decimals);

  num = num * decimals;
  num = Math.round(num);
  num = num / decimals;

  num = String(num);
  const reg = /(^[+-]?\d+)(\d{3})/;
  const tmp = num.split('.');
  let n = tmp[0];
  const d = tmp[1] ? dec_point + tmp[1] : '';

  while(reg.test(n)) n = n.replace(reg, "$1"+thousands_sep+"$2");

  return n + d;
}
