import styled from "styled-components";

const Lightning = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 13px;
  height: 20px;
  margin-top: 2px;
  margin-right: 10px;
  background-image: url(${require("/public/images/ui/ico-lightning.svg")});
`;

export default Lightning;
